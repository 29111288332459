import { useNavigate } from "react-router-dom";
import { Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import EmptyWorkspaceMessage from "@router/components/EmptyWorkspaceMessage/EmptyWorkspaceMessage";
import { useSelectInitialDocument } from "@router/hooks/useSelectInitialDocument";
import { AnalysisType } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

function AnalysisIndex() {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  useSelectInitialDocument({
    documentUrl: "analysis",
    documentPageMap: workspace.analysis.analysisMap,
    setActiveDocument: appStore.env.setActiveAnalysis,
  });

  const handleNewAnalysis = async (analysisType: AnalysisType) => {
    const newDoc = await workspace.analysis.createAnalysis({ analysisType });
    if (newDoc) {
      appStore.env.setActiveAnalysis(newDoc.id);
      navigate(newDoc.id);
    }
  };

  const addNewButtonElement = () => {
    if (appStore.env.featureFlags.enabled(FeatureFlag.SPREADSHEET_SUPPORT)) {
      return (
        <Popover
          position="bottom-left"
          content={
            <Menu>
              <MenuItem
                icon="code"
                text="Code block"
                onClick={() => handleNewAnalysis(AnalysisType.CodeBlock)}
                e2eIdentifiers="create-code-block"
              />
              <MenuItem
                icon="code"
                text="Spreadsheet"
                onClick={() => handleNewAnalysis(AnalysisType.Spreadsheet)}
                e2eIdentifiers="create-spreadsheet"
              />
            </Menu>
          }
        >
          <Button fill icon="plus" text="Create new analysis" e2eIdentifiers="create-analysis" />
        </Popover>
      );
    } else {
      return (
        <Button
          icon="plus"
          text="Create new code block"
          onClick={() => handleNewAnalysis(AnalysisType.CodeBlock)}
          e2eIdentifiers="add-new-document"
        />
      );
    }
  };

  return (
    <EmptyWorkspaceMessage
      className="analysis-index"
      documentNameSingular="analysis"
      documentNamePlural="analyses"
      hasDocument={!!appStore.workspaceModel?.analysis.analysisMap.size}
      addNewButtonEl={addNewButtonElement()}
    />
  );
}

export default AnalysisIndex;
