import { Classes } from "@blueprintjs/core";

export const closePopover = (selector = `.${Classes.OVERLAY_BACKDROP}`) => {
  const overlay = document.querySelector(selector);
  overlay?.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
};

export const scrollToBottom = (element: HTMLElement) => {
  element.scrollTop = element.scrollHeight;
};
