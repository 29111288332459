import { RollupEditorType } from "@rollup-types/editor";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";

export const calculateOrderListItemNumber = (reportBlock: IReportBlock): number => {
  const parentReport: IReport | undefined = reportBlock.parentReport;
  let listNumber = 1;

  if (parentReport) {
    const checkIfCountable = (block: IReportBlock) => {
      if (parentReport.validatedBlocks.length === 1) {
        return;
      }

      const currentIndex = parentReport.validatedBlocks.findIndex(b => b.id === block.id);
      const previousBlock = parentReport.validatedBlocks.at(currentIndex - 1);

      if (currentIndex && previousBlock && previousBlock.type === RollupEditorType.ol) {
        listNumber += 1;
        checkIfCountable(previousBlock);
      }
    };
    checkIfCountable(reportBlock);
  }

  return listNumber;
};

export const getReportBlockTypeByTextInput = (input: string): { type: RollupEditorType; trimmedText: string } => {
  const type = RollupEditorType.p;
  const trimmedText = input.trim();
  const numberWithDot = /^\d\./gm;
  const lowerCaseTrimmedText = trimmedText.toLowerCase();

  if (trimmedText.match(numberWithDot)) {
    return {
      type: RollupEditorType.ol,
      trimmedText: trimmedText.slice(2),
    };
  } else if (lowerCaseTrimmedText.startsWith("- [ ] ") || lowerCaseTrimmedText.startsWith("- [x] ")) {
    return {
      type: RollupEditorType.checklist,
      trimmedText: trimmedText.slice(6),
    };
  } else if (
    lowerCaseTrimmedText.startsWith("[ ] ") ||
    lowerCaseTrimmedText.startsWith("[x] ") ||
    lowerCaseTrimmedText.startsWith("- []")
  ) {
    return {
      type: RollupEditorType.checklist,
      trimmedText: trimmedText.slice(4),
    };
  } else if (lowerCaseTrimmedText.startsWith("[] ")) {
    return {
      type: RollupEditorType.checklist,
      trimmedText: trimmedText.slice(3),
    };
  } else if (trimmedText.startsWith("> ")) {
    return {
      type: RollupEditorType.quote,
      trimmedText: `<blockquote>${trimmedText.slice(2)}</blockquote>`,
    };
  } else if (trimmedText.startsWith("- ") || trimmedText.startsWith("* ")) {
    return {
      type: RollupEditorType.ul,
      trimmedText: trimmedText.slice(2),
    };
  }

  return {
    type,
    trimmedText,
  };
};

export const getImageResizerSrc = (input: string): string => {
  const regex = /<image-resizer[^>]*\s+src="([^"]+)"[^>]*>/i;
  const match = input.match(regex);
  return match ? match[1] : "";
};

export const getImageResizerCaption = (input: string): string => {
  const regex = /<image-resizer[^>]*\s+caption="([^"]+)"[^>]*>/i;
  const match = input.match(regex);
  return match ? match[1] : "";
};

export const covertReportImageBlockToMarkdown = (input: string): string => {
  if (!input) {
    return "";
  }

  const src = getImageResizerSrc(input);
  const caption = getImageResizerCaption(input);

  if (!src) {
    return "";
  }

  return `![${caption ? caption : "Image"}](${src})`;
};
