import { useState } from "react";
import { Icon, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { Text, TextVariant } from "@ui/Text";
import { CustomHeaderProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { getColDefs } from "@components/BomTables/Table/utils";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { MenuItem } from "@components/MenuItem";
import HeaderStatusNav from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderStatusNav";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { ITableColumn } from "@store/TableColumnStore";
import { EntityType } from "@store/types";

import styles from "./RequirementsHeaderCellRenderer.module.scss";

export interface IRequirementsHeaderCellRendererOwnProps {
  reqPageId: string;
  tableColumn: ITableColumn;
}

interface IRequirementsHeaderCellRendererProps extends IRequirementsHeaderCellRendererOwnProps, CustomHeaderProps {}

const RequirementsHeaderCellRenderer = (props: IRequirementsHeaderCellRendererProps) => {
  const { displayName, tableColumn, reqPageId, column, api } = props;
  const reqPage = appStore.workspaceModel?.requirementsModule.get(reqPageId);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const workspace = useWorkspace();
  const tableProxy = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  if (!reqPage) {
    return null;
  }

  const handleDeleteDefinition = () => {
    reqPage.removeColumn(tableColumn.id);
    const { entity } = tableColumn;
    if (entity?.type === EntityType.StatusDefinition) {
      const statusDefinition = workspace.statusDefinitionMap.get(entity.id);
      if (statusDefinition) {
        workspace.deleteStatusDefinition(statusDefinition);
      }
    }
    setShowConfirmation(false);
  };

  const handleStatusTypeChange = () => {
    if (tableProxy) {
      props.api.setGridOption("columnDefs", getColDefs(tableProxy.columnArray));
    }
  };

  const handleRemoveColumn = () => {
    reqPage.removeColumn(tableColumn.id);
  };

  const handleHideColumn = () => {
    api.setColumnsVisible([column], !column.isVisible());
    reqPage.toggleColumn(tableColumn.id);
  };

  const renderHeaderNav = () => {
    const { entity, metaColumn } = tableColumn;
    if (entity?.type === EntityType.StatusDefinition) {
      const statusDefinition = workspace.statusDefinitionMap.get(entity.id);
      return (
        <Menu>
          <HeaderStatusNav
            hideErase
            onStatusTypeChange={handleStatusTypeChange}
            statusDefinition={statusDefinition}
            onDelete={() => setShowConfirmation(true)}
            onRemove={handleRemoveColumn}
            onHide={handleHideColumn}
          />
        </Menu>
      );
    } else if (metaColumn) {
      return (
        <Menu>
          <MenuItem onClick={handleHideColumn} icon="eye-off" text="Hide" e2eIdentifiers="hide" />
        </Menu>
      );
    }

    return undefined;
  };

  const renderDisplayName = () => {
    const { entity } = tableColumn;

    if (entity) {
      const statusDefinition = workspace.statusDefinitionMap.get(entity.id);
      return statusDefinition?.label;
    }

    return displayName;
  };

  return (
    <div className={styles.requirementsHeaderCellRenderer}>
      <Text variant={TextVariant.H5} clamp={1}>
        {renderDisplayName()}
      </Text>
      <Popover position="bottom-left" content={renderHeaderNav()}>
        <Button minimal small icon={<Icon icon="drag-handle-vertical" size={12} />} e2eIdentifiers={["header-cell", "default"]} />
      </Popover>
      <DeleteConfirmationDialog
        isOpen={showConfirmation}
        titleItem="definition from the system"
        description="Are you sure you want to delete this definition? It will remove all related instances!"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDeleteDefinition}
      />
    </div>
  );
};

export default observer(RequirementsHeaderCellRenderer);
