import { useWorkspace } from "@hooks/useWorkspace";
import html2md from "html-to-md";
import { Html2MdOptions } from "html-to-md/dist/type";

import { MENTION_TAG_COMPONENT_TAG } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import { MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import { RollupEditorType } from "@rollup-types/editor";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import {
  covertReportImageBlockToMarkdown,
  downloadMarkdown,
  getAnalysisOutputById,
  getBlockById,
  getPropertyInstanceById,
  getReportById,
  sanitizeFilename,
} from "@utilities";
import { getAttachmentByIdFromCurrentWorkspace } from "@utilities/Attachment";
import { getAnalysisLink, getReportLink } from "@utilities/LinkUtils";
import { adjustTableToHaveHeader } from "@utilities/Table";

const useReportExport = (report: IReport) => {
  const { setIsPrintingPage } = appStore.ui;
  const workspace = useWorkspace();
  const { getPropertyLink, getAttachmentLink, getBlockLink } = useAppNavigate();
  const { hideKnowledgebaseSidePanel } = appStore.env.sidePanel;

  const exportToPdf = () => {
    const oldTitle = document.title;
    document.title = sanitizeFilename(report.label);
    hideKnowledgebaseSidePanel();
    setIsPrintingPage(true);

    // give time for the custom reference components to re-render to links view
    setTimeout(() => {
      window.print();
      setIsPrintingPage(false);
      document.title = oldTitle;
    }, 1000);
  };

  const getPropertyInstanceMentionLink = (id: string, label?: string) => {
    const propertyInstance = getPropertyInstanceById(id);

    if (propertyInstance) {
      return `<a href="${getPropertyLink(id)}">${label !== undefined ? label : propertyInstance.numericText}</a>`;
    }

    return label || "Unknown";
  };

  const getReportMentionLink = (id: string, label: string) => {
    const report = getReportById(id);

    if (report) {
      return `<a href="${getReportLink(workspace.id, id)}">${label}</a>`;
    }

    return label;
  };

  const getAnalysisLinkByOutputId = (id: string, label: string) => {
    const analysisOutput = getAnalysisOutputById(id);

    if (analysisOutput?.analysis) {
      return `<a href="${getAnalysisLink(workspace.id, analysisOutput.analysis.id)}">${label}</a>`;
    }

    return label;
  };

  const getBlockMentionLink = (id: string, label: string) => {
    const block = getBlockById(id);

    if (block) {
      return `<a href="${getBlockLink(id)}">${label}</a>`;
    }

    return label;
  };

  const getAttachmentMentionLink = (id: string, label: string) => {
    const attachment = getAttachmentByIdFromCurrentWorkspace(id);

    if (attachment) {
      return `<a href="${getAttachmentLink(id)}">${label}</a>`;
    }

    return label;
  };

  const covertReportTableBlockToMarkdown = (input: string) => {
    const adjustedTable = adjustTableToHaveHeader(input);
    return convertReportBlockHtmlToMarkdown(adjustedTable, { ignoreTags: ["colgroup"] });
  };

  const replaceMentionTags = (htmlString: string) => {
    return htmlString.replace(
      /<mention-tag-component\s+data-id="([^"]+)"\s+data-label="([^"]+)"(?:\s+[^>]*?)?>.*?<\/mention-tag-component>/g,
      (_match, value, label) => {
        const [entityType, id] = value.split(":") as [MentionType, string];

        if (!workspace) {
          return label;
        }

        switch (entityType) {
          case MentionType.PropertyInstanceName:
            return getPropertyInstanceMentionLink(id, label);
          case MentionType.PropertyInstanceValue:
            return getPropertyInstanceMentionLink(id);
          case MentionType.Attachment:
            return getAttachmentMentionLink(id, label);
          case MentionType.Block:
            return getBlockMentionLink(id, label);
          case MentionType.Report:
            return getReportMentionLink(id, label);
          case MentionType.AnalysisOutput:
            return getAnalysisLinkByOutputId(id, label);
          default:
            return label;
        }
      }
    );
  };

  const convertReportBlockHtmlToMarkdown = (content: string, options?: Html2MdOptions) => {
    let converted = content;

    if (content.includes(MENTION_TAG_COMPONENT_TAG)) {
      converted = replaceMentionTags(converted);
    }

    converted = html2md(converted, options);

    return converted;
  };

  const handleReportBlockToMdString = (reportBlock: IReportBlock) => {
    const { label, checked, type } = reportBlock;

    switch (type) {
      case RollupEditorType.image:
        return covertReportImageBlockToMarkdown(label);
      case RollupEditorType.table:
        return covertReportTableBlockToMarkdown(label);
      case RollupEditorType.hr:
        return "---";
      case RollupEditorType.ol:
        return `1. ${convertReportBlockHtmlToMarkdown(label)}`;
      case RollupEditorType.ul:
        return `- ${convertReportBlockHtmlToMarkdown(label)}`;
      case RollupEditorType.checklist:
        return `- [${checked ? "x" : " "}] ${convertReportBlockHtmlToMarkdown(label)}`;
      default:
        return convertReportBlockHtmlToMarkdown(label);
    }
  };

  const exportToMarkdown = () => {
    const coverString = report.coverUrl ? `![Report cover](${report.coverUrl}) \n` : "";
    const titleString = `# ${report.icon || ""} ${report.label || "Untitled Report"}`;
    const fileContent = report.validatedBlocks.reduce((acc, block) => {
      return acc + `\n${handleReportBlockToMdString(block)}`;
    }, `${coverString}${titleString}`);

    downloadMarkdown(fileContent, `${sanitizeFilename(report.label)}.md`);
  };

  return {
    exportToPdf,
    exportToMarkdown,
  };
};

export default useReportExport;
