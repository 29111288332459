import { useCallback } from "react";
import { Icon, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";

interface IBlockTagProps {
  block: IBlock;
  className?: string;
  labelClassName?: string;
}

const BlockTag = ({ block, className, labelClassName }: IBlockTagProps) => {
  const { navigateToBlock, getBlockLink } = useAppNavigate();

  const handleBlockClicked = useCallback(() => {
    if (block) {
      navigateToBlock(block.id);
    }
  }, [block, navigateToBlock]);

  const renderContent = () => (
    <>
      <Icon icon="cube" />
      <span className={labelClassName}>{block.label}</span>
    </>
  );

  if (appStore.ui.isPrintingPage) {
    return (
      <a className={className} href={getBlockLink(block.id)}>
        {renderContent()}
      </a>
    );
  }

  return (
    <Tooltip hoverOpenDelay={500} placement={Position.BOTTOM} content={block.path}>
      <div className={className} onClick={handleBlockClicked}>
        {renderContent()}
      </div>
    </Tooltip>
  );
};

export default observer(BlockTag);
