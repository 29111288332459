import { KeyboardEvent, useCallback } from "react";
import { EditorView } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";
import { isAlive } from "mobx-state-tree";

import { createMentionTagComponent } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import {
  getMentionAttributes,
  getMentionItemFromText,
  handleBackspaceIfMention,
} from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { ARROW_DOWN_KEY, ARROW_UP_KEY, BACKSPACE_KEY, ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import { getReportBlockTypeByTextInput } from "@utilities/ReportBlocks";
import { focusNextBlock, focusPreviousBlock, getCaretPosition, mergeContentWithPreviousBlock } from "@utilities/TipTap";

import { allContentExtensions, TIPTAP_EMPTY_CONTENT } from "./constants";
import { focusReportBlockById } from "./utils";

export const endPositionFixer = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.quote:
      return 0;
    default:
      return 1;
  }
};

export const startPositionFixer = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.quote:
      return 2;
    case RollupEditorType.table:
      return 0;
    default:
      return 1;
  }
};

export const useEditorHandlers = (params: {
  reportBlock: IReportBlock;
  editor?: Editor | null;
  workspaceId?: string;
  onAddReportBlock(block: IReportBlock, type?: RollupEditorType, label?: string, above?: boolean): void;
}) => {
  const { reportBlock, editor, workspaceId, onAddReportBlock } = params;
  const report = reportBlock.parentReport as IReport | undefined;

  const bulkCreateReportBlocks = useCallback(
    (dto: { type: RollupEditorType; label: string }[]) => {
      if (report && dto.length) {
        const orderIndex = report.validatedBlocks.findIndex(b => b.id === reportBlock.id) + 1;
        const lastCreatedBlockId = appStore.workspaceModel?.addReportBlocks(report, dto, orderIndex);
        lastCreatedBlockId && focusReportBlockById(lastCreatedBlockId, true);
      }

      return;
    },
    [report, reportBlock.id]
  );

  const handleEnterKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType) => {
      if (event.key === ENTER_KEY && !event.shiftKey) {
        const cursorPosition = editor?.state.selection.$anchor.pos || 1;
        const isAbove = editor?.state.selection.$anchor.pos === 1 && !editor?.isEmpty;
        const doc = editor?.state?.doc;
        let label = "";

        if (doc && !isAbove) {
          const textBefore = doc.cut(0, cursorPosition).toJSON();
          const content = doc.cut(cursorPosition, doc.content.size).toJSON();
          const temporaryEditorInstance = new Editor({ content, extensions: allContentExtensions });
          const temporaryContent = temporaryEditorInstance.getHTML();
          const temporaryText = temporaryEditorInstance.getText();
          const isEmpty = !temporaryText.replaceAll("\n", "");
          if (!isEmpty && TIPTAP_EMPTY_CONTENT !== temporaryContent) {
            label = temporaryContent;
          }
          editor?.commands.setContent(textBefore);
        }

        onAddReportBlock(reportBlock, type, label, isAbove);
      }
    },
    [editor?.commands, editor?.isEmpty, editor?.state?.doc, editor?.state.selection.$anchor.pos, onAddReportBlock, reportBlock]
  );

  const handleModEnterKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
      if ((event.key === ENTER_KEY && event.metaKey) || (event.key === ENTER_KEY && event.ctrlKey)) {
        onAddReportBlock(reportBlock, type, label);
      }
    },
    [onAddReportBlock, reportBlock]
  );

  const handleShiftEnterKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
      if (event.key === ENTER_KEY && event.shiftKey) {
        onAddReportBlock(reportBlock, type, label);
      }
    },
    [onAddReportBlock, reportBlock]
  );

  const handleBackspaceKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== BACKSPACE_KEY || !editor || !report) {
        return;
      }

      const isMention = handleBackspaceIfMention({ editor });
      if (isMention) {
        return;
      }

      if (reportBlock.type !== RollupEditorType.p && editor?.isEmpty) {
        reportBlock.updateType(RollupEditorType.p);
        return;
      }

      if (editor?.isEmpty) {
        focusPreviousBlock(reportBlock, report.validatedBlocks);
        appStore.workspaceModel?.deleteReportBlock(reportBlock);
      } else if (getCaretPosition(editor) === 1 && !editor?.isEmpty) {
        mergeContentWithPreviousBlock(reportBlock, report.validatedBlocks, editor.getHTML());
      }
    },
    [editor, report, reportBlock]
  );

  const handleArrowDownKey = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ARROW_DOWN_KEY && report && report.validatedBlocks.length > 1 && editor) {
        const endPosition = endPositionFixer(reportBlock.type);
        const canJumpDown = editor.getText().length + endPosition <= editor.state.selection.$anchor.pos;
        if (canJumpDown) {
          focusNextBlock(reportBlock, report.validatedBlocks);
        }
      }
    },
    [editor, report, reportBlock]
  );

  const handleArrowUpKey = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ARROW_UP_KEY && report && report.validatedBlocks.length > 1 && editor) {
        const startPosition = startPositionFixer(reportBlock.type);
        const canJumpUp = editor.state.selection.$anchor.pos === startPosition || editor.isEmpty;
        if (canJumpUp) {
          focusPreviousBlock(reportBlock, report.validatedBlocks);
        }
      }
    },
    [editor, report, reportBlock]
  );

  const handlePaste = useCallback(
    (_view: EditorView, e: ClipboardEvent) => {
      if (!editor || !workspaceId) return;
      const pastedText = e.clipboardData?.getData("text") || "";
      const futureBlocks = pastedText.split("\n");

      if (futureBlocks.length > 1) {
        const bulkCreateDto: { type: RollupEditorType; label: string }[] = [];
        futureBlocks.map((text, index) => {
          // we skip the first item because existing block will be populated from it
          if (index) {
            const { type, trimmedText } = getReportBlockTypeByTextInput(text);
            bulkCreateDto.push({ type, label: trimmedText });
          }
        });
        bulkCreateReportBlocks(bulkCreateDto);
      }

      const entity = futureBlocks[0] ? getMentionItemFromText(futureBlocks[0], workspaceId) : undefined;
      const mentionTagAttrs = entity && getMentionAttributes(entity);

      if (mentionTagAttrs) {
        const mentionTagComponent = createMentionTagComponent(mentionTagAttrs.id, mentionTagAttrs.label, true);
        // append content to the editor
        editor?.chain().focus().insertContent(mentionTagComponent).run();
      } else {
        const { type, trimmedText } = getReportBlockTypeByTextInput(futureBlocks[0]);
        editor?.chain().focus().insertContent(trimmedText).run();
        type !== reportBlock.type && reportBlock.updateType(type);
      }

      editor?.chain().focus();
      return true; // prevents default pasting
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, reportBlock, workspaceId]
  );

  const handleBlockUpdate = useCallback(
    (content: string) => {
      if (isAlive(reportBlock)) {
        reportBlock.updateText(content);
      }
    },
    [reportBlock]
  );

  return {
    handleEnterKeyDown,
    handleShiftEnterKeyDown,
    handleModEnterKeyDown,
    handleBackspaceKeyDown,
    handleArrowUpKey,
    handleArrowDownKey,
    handlePaste,
    handleBlockUpdate,
    bulkCreateReportBlocks,
  };
};
