import { MouseEvent, useEffect, useRef, useState } from "react";
import { Menu, PopoverPosition } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { IconName } from "@blueprintjs/icons";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import {
  getTooltipContent,
  getVerificationMethodIcon,
  getVerificationMethodText,
} from "@components/Requirements/Page/RequirementPageUtils";
import { SafeTooltip } from "@components/SmallComponents";
import { Tag } from "@components/Tag";
import { RequirementVerificationMethod } from "@rollup-api/models/requirementBlock";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import "./MethodCell.scss";

type Props = {
  requirementBlock: IRequirementBlock;
};

interface IVerificationMethod {
  type: RequirementVerificationMethod;
  icon: IconName | MaybeElement;
  text: string;
  tooltipContent: string;
}

const createVerificationMethod = (type: RequirementVerificationMethod): IVerificationMethod => ({
  type: type,
  icon: getVerificationMethodIcon(type),
  text: getVerificationMethodText(type),
  tooltipContent: getTooltipContent(type),
});

const MethodCell = (props: Props) => {
  const { requirementBlock } = props;
  const verificationMethods = requirementBlock.verificationMethods.map(createVerificationMethod);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [tagContainerWidth, setTagContainerWidth] = useState(0);
  const [tagContainerHeight, setTagContainerHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setTagContainerWidth(ref.current.offsetWidth);
      setTagContainerHeight(ref.current.offsetHeight);
    }
  }, [verificationMethods.length]);

  const renderMenuItem = (type: RequirementVerificationMethod) => {
    const method = createVerificationMethod(type);

    const onMenuItemClick = (e: MouseEvent) => {
      e.stopPropagation();
      requirementBlock.toggleVerificationMethod(method.type);
    };

    return (
      <SafeTooltip
        className="method-cell--menu-item-tooltip"
        popoverClassName="method-cell--menu-item-tooltip-popover"
        content={method.tooltipContent}
        position={PopoverPosition.RIGHT}
      >
        <MenuItem
          icon={method.icon}
          onClick={onMenuItemClick}
          text={method.text}
          e2eIdentifiers={method.text}
          rightElement={
            requirementBlock.verificationMethods.includes(method.type) ? <BlueprintIcon onClick={onMenuItemClick} icon="tick" /> : undefined
          }
        />
      </SafeTooltip>
    );
  };

  const getMenuContent = () => (
    <Menu>
      {renderMenuItem(RequirementVerificationMethod.Inspection)}
      {renderMenuItem(RequirementVerificationMethod.Simulation)}
      {renderMenuItem(RequirementVerificationMethod.Analysis)}
      {renderMenuItem(RequirementVerificationMethod.Demonstration)}
      {renderMenuItem(RequirementVerificationMethod.Test)}
      {renderMenuItem(RequirementVerificationMethod.Sample)}
    </Menu>
  );

  const renderTags = () => {
    return (
      <div ref={ref} className="method-cell--tags-container">
        {verificationMethods.map(method => (
          <SafeTooltip
            className="method-cell--tag-tooltip"
            popoverClassName="method-cell--tag-tooltip-popover"
            key={method.type}
            content={getTooltipContent(method.type)}
            disabled={isPopoverOpen}
            position={PopoverPosition.RIGHT}
          >
            <Tag minimal>{method.text}</Tag>
          </SafeTooltip>
        ))}
      </div>
    );
  };

  return (
    <div className="method-cell">
      {/* Since CellDropdown uses "position: absolute", it was necessary to render
          this dummy element that will make sure AgGrid recalculates row height
          whenever the verification methods change */}
      <div style={{ height: tagContainerHeight, width: tagContainerWidth }} />
      <CellDropdown
        content={getMenuContent()}
        e2eIdentifiers="method-cell-dropdown"
        disabled={requirementBlock.locked}
        text={renderTags()}
        onInteraction={setIsPopoverOpen}
      />
    </div>
  );
};

export default observer(MethodCell);
