import { useRef } from "react";
import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import styles from "./SearchInput.module.scss";

interface ISearchInputProps {
  searchValue: string;
  setSearchValue(value: string): void;
}

const SearchInput = (props: ISearchInputProps) => {
  const { searchValue, setSearchValue } = props;
  const inputGroupRef = useRef<HTMLInputElement>(null);

  return (
    <InputGroup
      className={styles.searchInput}
      inputRef={inputGroupRef}
      leftElement={<Button minimal icon="search" onClick={() => inputGroupRef.current?.focus()} e2eIdentifiers="search-btn" />}
      rightElement={
        searchValue ? <Button minimal icon="cross" onClick={() => setSearchValue("")} e2eIdentifiers="clear-search-btn" /> : undefined
      }
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      placeholder="Search"
    />
  );
};

export default observer(SearchInput);
