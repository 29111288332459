import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CommentsPanel from "@components/CommentsPanel/CommentsPanel";
import { RightSidePanels } from "@components/RightSidePanels";
import appStore from "@store/AppStore";
import { ICommentFeed } from "@store/CommentFeedStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { ReqSidePanel } from "@store/SidePanelStore";
import { ITableColumn } from "@store/TableColumnStore";

import { RequirementDetailsPanel } from "../RequirementDetailsPanel";

import { RequirementsColumnTogglePanel } from "./RequirementsColumnTogglePanel";

interface IRequirementsRightSidePanelsProps {
  commentFeed: ICommentFeed;
  childrenIds: string[];
  columns: ITableColumn[];
  activeReqBlock?: IRequirementBlock;
  activeSidePanel?: ReqSidePanel;
  onClearCommentHistory(): void;
}

const RequirementsRightSidePanels = (props: IRequirementsRightSidePanelsProps) => {
  const { commentFeed, activeSidePanel, childrenIds, activeReqBlock, columns, onClearCommentHistory } = props;
  const { pinnedReqSidePanels } = appStore.env.sidePanel;

  const handleCloseColumnTogglePanel = () => {
    if (activeSidePanel === ReqSidePanel.EditColumns) {
      appStore.env.sidePanel.hideReqSidePanel();
    }
  };

  const renderPanel = (panel?: ReqSidePanel) => {
    switch (panel) {
      case ReqSidePanel.EditColumns:
        return <RequirementsColumnTogglePanel columns={columns} onClose={handleCloseColumnTogglePanel} />;
      case ReqSidePanel.Comments:
        return <CommentsPanel commentFeed={commentFeed} additionalParentIds={childrenIds} onClearCommentHistory={onClearCommentHistory} />;
      case ReqSidePanel.RequirementDetails:
        if (!activeReqBlock) {
          return <NonIdealState title="No active Requirement Block" description="You can select a block by clicking on its field" />;
        }

        return <RequirementDetailsPanel reqBlock={activeReqBlock} />;

      default:
        return null;
    }
  };
  return (
    <RightSidePanels<ReqSidePanel> activeSidePanel={activeSidePanel} pinnedSidePanels={pinnedReqSidePanels} renderPanel={renderPanel} />
  );
};

export default observer(RequirementsRightSidePanels);
