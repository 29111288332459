import { ReactNode } from "react";
import { PopoverPosition } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { EntityAnnotationListPopover } from "@components/EntityAnnotationListPopover";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import { StoreType } from "@store/types";

interface IKbAnnotationPopoverProps {
  reportBlock: IReportBlock;
  report: IReport;
  children: ReactNode;
  isOpen?: boolean;
  onInteraction?(nextOpenState: boolean): void;
}

const KbAnnotationPopover = (props: IKbAnnotationPopoverProps) => {
  const { report, reportBlock, children, isOpen, onInteraction } = props;

  return (
    <EntityAnnotationListPopover
      className="report-block--popover-target"
      entityId={reportBlock.id}
      entityIdsWithComments={report.validatedBlockIdsWithComments}
      annotationList={reportBlock.annotationList}
      storeType={StoreType.ReportBlock}
      isOpen={isOpen}
      onInteraction={onInteraction}
      popoverProps={{ position: PopoverPosition.BOTTOM_LEFT }}
      disableOpenOnTargetClick
    >
      {children}
    </EntityAnnotationListPopover>
  );
};

export default observer(KbAnnotationPopover);
