import { Outlet, useMatch } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";

import { FeatureLayout } from "@components/FeatureLayout";
import { SidePanelProjectList } from "@components/ProjectManagement/SidePanelProjectList";
import { TNavigationLinkConfig } from "@constants/navigation";
import { getPmLayoutMenuItems } from "@constants/pm-navigation";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

const PmInnerLayout = () => {
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();
  const linksMapper = (link: TNavigationLinkConfig): TNavigationLinkConfig & { selected: boolean } => ({
    ...link,
    ...(link.nestedLinks && { nestedLinks: link.nestedLinks.map(linksMapper) }),
    selected: navMatch?.params?.view === link.to || [nestedNavMatch?.params?.view, nestedNavMatch?.params?.subView].join("/") === link.to,
  });
  const navMatch = useMatch(`/workspaces/:workspaceId/modeling/:view/*`);
  const nestedNavMatch = useMatch(`/workspaces/:workspaceId/modeling/:view/:subView/*`);

  const hoverMenuMenuItems = getPmLayoutMenuItems("hover-menu").map(linksMapper);
  const menuItems = getPmLayoutMenuItems().map(linksMapper);

  if (appStore.env.isSinglePaneMode) {
    return <Outlet />;
  }

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={<SidePanelProjectList />}
      hoverMenuMenuItems={hoverMenuMenuItems}
      minSize={minSize}
      maxSize={maxSize}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        hideScroll
        open={open}
        toggle={toggle}
        title="Project Management"
        menuItems={menuItems}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        <SidePanelProjectList />
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
};

export default PmInnerLayout;
