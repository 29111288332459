import {
  combineValues,
  DependencyEdge,
  DependencyGraph,
  DependencyNode,
  determineExpressionDependencies,
  EvaluatedExpression,
  EvaluationError,
  fillDependencyGraph,
  isValidEvaluation,
  MathResult,
  PropertyDataType,
  PropertyDependency,
  PropertyInstance,
  RollupPropertyInfo,
  substituteDataLinks,
  toNumber,
  Workspace,
} from "@rollup-io/engineering";
import { DirectedGraph } from "graphology";
import assignIn from "lodash/assignIn";
import { getParent, IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreatePropertyInstanceDto, PropertyInstanceUpdateDto } from "@rollup-api/models/propertyInstance";
import { lockPropertyInstance, unlockPropertyInstance, updatePropertyInstance } from "@rollup-api/utils";
import { AnnotationListStore } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { BlockStore, IBlock } from "@store/BlockStore";
import { IPropertyDefinition, PropertyDefinitionStore } from "@store/PropertyDefinitionStore";
import { StoreType } from "@store/types";
import { calculateRollupValue, evaluateSmartExpression, FormatScalarValue, formatSmartExpression } from "@utilities";

export enum PropertyInstanceValidationErrorMsg {
  EmptyExpression = "Empty expression",
  Unknown = "Unknown validation error",
}

interface IPropertyInstanceStoreVolatile {
  lastFocusedCommentId?: string;
}

export const PropertyInstanceStore = types
  .model(StoreType.PropertyInstance, {
    id: types.identifier,
    createdAt: types.maybe(types.string),
    parentBlock: types.safeReference(types.late((): IAnyModelType => BlockStore)),
    propertyDefinition: types.safeReference(PropertyDefinitionStore),
    locked: types.optional(types.boolean, false),
    propertyGroup: types.maybe(types.string),
    value: types.optional(types.string, ""),
    lowerBoundUncertainty: types.optional(types.number, 0),
    upperBoundUncertainty: types.optional(types.number, 0),
    annotationList: types.optional(AnnotationListStore, () => AnnotationListStore.create({})),
  })
  .volatile<IPropertyInstanceStoreVolatile>(() => ({
    lastFocusedCommentId: undefined,
  }))
  .actions(self => ({
    patch(update: PropertyInstanceUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "parentBlock", "propertyDefinition", "ui"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    sendUpdate(updateDto: PropertyInstanceUpdateDto) {
      updatePropertyInstance(self.id, updateDto);
    },
    setPropertyDefinition(propertyDefinition: IPropertyDefinition) {
      self.propertyDefinition = propertyDefinition;
    },
    setScalarValue(scalarValue: number) {
      appStore.env.setActivePropertyInstance(self as IPropertyInstance);
      if (self.locked || !isFinite(scalarValue) || self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return false;
      }

      if (self.value === scalarValue.toString()) {
        return false;
      }

      self.value = scalarValue.toString();
      this.sendUpdate({ value: self.value });
      return true;
    },
    setLowerBoundUncertainty(value: number, disableNotification?: boolean) {
      const absValue = Math.abs(value);
      if (absValue !== self.lowerBoundUncertainty) {
        self.lowerBoundUncertainty = absValue;
        if (!disableNotification) {
          this.sendUpdate({ lowerBoundUncertainty: value });
        }
      }
    },
    setUpperBoundUncertainty(value: number, disableNotification?: boolean) {
      const absValue = Math.abs(value);
      if (absValue !== self.upperBoundUncertainty) {
        self.upperBoundUncertainty = absValue;
        if (!disableNotification) {
          this.sendUpdate({ upperBoundUncertainty: value });
        }
      }
    },
    setLastFocusedCommentId(id?: string) {
      self.lastFocusedCommentId = id;
    },
  }))
  .actions(self => ({
    setStringValue(value: string, disableNotification?: boolean) {
      if (self.locked || self.propertyDefinition?.dataType !== PropertyDataType.string || self.value === value) {
        return false;
      }

      self.value = value;
      if (!disableNotification) {
        self.sendUpdate({ value });
      }
      return true;
    },
    setGroup(propertyGroup: string) {
      self.propertyGroup = propertyGroup;
      if (self.propertyDefinition?.instances?.length === 1) {
        // Propagate group change if instance is a singleton. Need to lookup group label from group ID
        const group = (self.parentBlock as IBlock)?.propertyGroups?.find(g => g.id === propertyGroup);
        if (group) {
          console.debug(`Setting default group to ${group.label}`);
          self.propertyDefinition.setDefaultPropertyGroup(group.label);
        }
      }
      self.sendUpdate({ propertyGroup });
    },
    clearGroup() {
      self.propertyGroup = "";
      if (self.propertyDefinition?.instances?.length === 1) {
        // Propagate group change if instance is a singleton
        self.propertyDefinition.setDefaultPropertyGroup(undefined);
      }
      self.sendUpdate({ propertyGroup: "" });
    },
    setLocked(locked: boolean, notify = true) {
      self.locked = locked;
      if (notify) {
        if (locked) {
          lockPropertyInstance(self.id);
        } else {
          unlockPropertyInstance(self.id);
        }
      }
    },
    toggleLocked() {
      this.setLocked(!self.locked);
    },
  }))
  .views(self => ({
    get parentBlockId(): string {
      return self.parentBlock?.id ?? "";
    },
    get propertyDefinitionId(): string {
      return self.propertyDefinition?.id ?? "";
    },
    get dependencySubGraph(): DependencyGraph | undefined {
      return (getParent(self, 2) as any)?.graphMap?.get(self.id)?.graph;
    },
    get dependencyGraph() {
      const graph = new DirectedGraph<DependencyNode, DependencyEdge>();
      fillDependencyGraph(getParent(self, 2), self as any, graph);
      return graph;
    },
    get isCyclic(): boolean {
      // Some ugly typing to avoid cyclic dependencies in MST
      const isCyclic = (getParent(self, 2) as any)?.graphMap?.get(self.id)?.isCyclic;
      if (isCyclic) {
        console.warn(`Property instance ${self.id} has cyclic dependencies`);
      }
      return isCyclic;
    },
    get hasComment(): boolean {
      return self.annotationList.hasComment;
    },
  }))
  .views(self => ({
    get evaluatedExpressionInfo(): EvaluatedExpression | undefined {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return undefined;
      }

      if (self.isCyclic) {
        return {
          originalExpression: self.value,
          errorType: EvaluationError.CircularDependency,
          message: "Expression has cyclic dependencies",
          substitutedExpression: self.value,
          propertyInstances: [],
          dataLinks: [],
        };
      }

      return evaluateSmartExpression(self.value, self as IPropertyInstance);
    },
    get unit() {
      return this.evaluatedExpressionInfo?.calculatedResult?.formatUnits() ?? self.propertyDefinition?.unit;
    },
    get isValid() {
      return !!self.parentBlock && !!self.propertyDefinition;
    },
    get validationErrorMessage() {
      if (!self.value) {
        return PropertyInstanceValidationErrorMsg.EmptyExpression;
      }
      if (isValidEvaluation(this.evaluatedExpressionInfo)) {
        return undefined;
      }
      return this.evaluatedExpressionInfo?.message ?? PropertyInstanceValidationErrorMsg.Unknown;
    },
  }))
  .views(self => ({
    get label(): string {
      return self.propertyDefinition?.label ?? "";
    },
    get path(): string | undefined {
      if (self.parentBlock && self.propertyDefinition) {
        const blockPath = self.parentBlock.isDetachedBlock ? self.parentBlock.label : self.parentBlock.path;
        if (!blockPath) {
          return `/:${self.propertyDefinition.label}`;
        }
        return `${blockPath}:${self.propertyDefinition.label}`;
      }
      return undefined;
    },
    get compactPath(): string | undefined {
      if (self.parentBlock && self.propertyDefinition) {
        return `${self.parentBlock.label}:${self.propertyDefinition.label}`;
      }
      return this.label;
    },
    get rollupInfo(): RollupPropertyInfo | undefined {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar || !self.propertyDefinition.autoRollupChildren) {
        return undefined;
      }
      return calculateRollupValue(self as IPropertyInstance);
    },
    get isRollup(): boolean {
      try {
        return (
          self.propertyDefinition?.dataType === PropertyDataType.scalar &&
          self.propertyDefinition.autoRollupChildren &&
          !!this.rollupInfo?.dependencies?.length
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    get instanceDependencies(): (PropertyDependency | undefined)[] {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return [];
      } else {
        return determineExpressionDependencies(getParent(self, 2) as Workspace, self as PropertyInstance, self.value);
      }
    },
    get intrinsicValue(): number | undefined {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return undefined;
      }
      return toNumber(self.evaluatedExpressionInfo?.calculatedResult);
    },
    get combinedResult(): MathResult | undefined {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return undefined;
      }

      if (this.isRollup) {
        return combineValues(self.evaluatedExpressionInfo, this.rollupInfo);
      }
      return self.evaluatedExpressionInfo?.calculatedResult;
    },
    get rollupValue(): number | undefined {
      return toNumber(this.rollupInfo?.calculatedResult);
    },
    get numericValue(): number {
      return toNumber(this.combinedResult) ?? 0;
    },
    get rollupExpression(): string | undefined {
      if (!this.isRollup) {
        return undefined;
      }

      const rollupValue = toNumber(this.rollupInfo?.calculatedResult) ?? 0;
      if (this.intrinsicValue) {
        return `${FormatScalarValue(this.numericValue)} (${FormatScalarValue(rollupValue)} + ${FormatScalarValue(this.intrinsicValue)})`;
      } else {
        return FormatScalarValue(this.numericValue);
      }
    },
  }))
  .views(self => ({
    get effectiveUnit(): string {
      // TODO: can this be removed after refactoring?
      return self.evaluatedExpressionInfo?.calculatedResult?.formatUnits() ?? self.propertyDefinition?.unit ?? "";
    },
    // Shows the numeric value along with units
    get numericText(): string {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return self.value;
      }
      const unit = this.effectiveUnit;
      if (unit) {
        return `${self.numericValue ?? 0} ${unit}`;
      }
      return `${self.numericValue ?? 0}`;
    },
    get intrinsicText(): string {
      if (self.propertyDefinition?.dataType !== PropertyDataType.scalar) {
        return self.value;
      }
      if (self.value === undefined || self.value === "") {
        return "";
      }
      const unit = this.effectiveUnit;
      if (unit) {
        return `${self.intrinsicValue ?? 0} ${unit}`;
      }
      return `${self.intrinsicValue ?? 0}`;
    },
  }))
  .views(self => ({
    get effectivePropertyGroup(): string | undefined {
      // Only defer to property definition's group if the instance doesn't explicitly define it
      if (self.propertyGroup === undefined && self.propertyDefinition?.defaultPropertyGroup) {
        const label = self.propertyDefinition.defaultPropertyGroup;
        // Have to cast parentBlock explicitly because of types.late
        return (self.parentBlock as IBlock)?.propertyGroups?.find(g => g.label === label)?.id;
      }
      return self.propertyGroup;
    },
  }))
  .actions(self => ({
    setScalarValueFromString(value?: string, disableNotification?: boolean): { success: boolean; message?: string } {
      if (self.locked) {
        return { success: false, message: "Property value is locked" };
      }

      if (typeof value !== "string") {
        return { success: false, message: "Value is not a string" };
      }

      if (value === self.value) {
        return { success: true };
      }

      // Substitute any existing data links with their sources
      if (appStore.workspaceModel) {
        const res = substituteDataLinks(appStore.workspaceModel, value);

        if (res.requiredDataLinks?.length) {
          for (const link of res.requiredDataLinks) {
            appStore.workspaceModel.addOrUpdateDataLink(link).catch(err => {
              console.warn(err);
            });
          }
        }
        value = res.substitutedExpression;
      }

      // Contract the smart expression string to replace any dot paths with IDs.
      // This allows properties and blocks to be renamed or re-parented without breaking smart expressions.
      value = formatSmartExpression(value, false) ?? "";
      const valueChanged = self.value !== value;
      self.value = value;

      const unit = self.evaluatedExpressionInfo?.calculatedResult?.formatUnits();

      // Propagate unit change if instance is a singleton and unit is not empty
      // Unit change needs to happen before setting the value, in order to ensure
      // that unit conversion happens first
      if (unit && self.propertyDefinition?.instances?.length === 1) {
        self.propertyDefinition.setUnit(unit ?? "");
      }

      if (valueChanged && !disableNotification) {
        self.sendUpdate({ value });
      }

      if (isValidEvaluation(self.evaluatedExpressionInfo)) {
        return { success: true };
      } else {
        return { success: false, message: self.evaluatedExpressionInfo?.message || "Error evaluating expression" };
      }
    },
  }));

export function subscribeToPropertyInstanceEvents(socket: Socket) {
  socket.on(
    "createPropertyInstance",
    (data: { workspaceId: string; createPropertyInstanceDto: CreatePropertyInstanceDto; userId: string }) => {
      if (
        data.createPropertyInstanceDto?.id &&
        data.createPropertyInstanceDto.parentBlock &&
        data.createPropertyInstanceDto.propertyDefinition &&
        data.workspaceId === appStore.workspaceModel?.id
      ) {
        const parentBlock = appStore.workspaceModel.blockMap.get(data.createPropertyInstanceDto.parentBlock);
        const propertyDefinition = appStore.workspaceModel.propertyDefinitionMap.get(data.createPropertyInstanceDto.propertyDefinition);
        if (parentBlock && propertyDefinition) {
          const { propertyGroup, id, value } = data.createPropertyInstanceDto;
          appStore.workspaceModel.addPropertyInstance(parentBlock, propertyDefinition, propertyGroup, value, id, false);
        }
      }
    }
  );

  socket.on("deletePropertyInstance", (data: { workspaceId: string; id: string; userId: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.propertyInstanceMap.get(data.id);
      if (instance) {
        appStore.workspaceModel.deletePropertyInstance(instance, false);
      }
    }
  });

  socket.on(
    "updatePropertyInstance",
    (data: { workspaceId: string; id: string; updatePropertyInstanceDto: PropertyInstanceUpdateDto; userId: string }) => {
      if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
        const instance = appStore.workspaceModel.propertyInstanceMap.get(data.id);
        instance?.patch(data.updatePropertyInstanceDto);
      }
    }
  );

  socket.on(
    "reorderPropertyInstance",
    (data: { workspaceId: string; id: string; reorderPropertyInstanceDto: { destinationId: string } }) => {
      if (data.id && data.reorderPropertyInstanceDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
        const instance = appStore.workspaceModel.propertyInstanceMap.get(data.id);
        const block = instance?.parentBlock;
        block?.movePropertyInstance(data.id, data.reorderPropertyInstanceDto.destinationId, false);
      }
    }
  );

  socket.on("lockPropertyInstance", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.propertyInstanceMap.get(data.id);
      instance?.setLocked(true, false);
    }
  });

  socket.on("unlockPropertyInstance", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const instance = appStore.workspaceModel.propertyInstanceMap.get(data.id);
      instance?.setLocked(false, false);
    }
  });
}

export interface IPropertyInstance extends Instance<typeof PropertyInstanceStore> {}

export interface IPropertyInstanceSnapshotIn extends SnapshotIn<typeof PropertyInstanceStore> {}

interface IPropertyInstanceSnapshotOut extends SnapshotOut<typeof PropertyInstanceStore> {}

export interface IPropertyInstanceMobxType extends IType<IPropertyInstanceSnapshotIn, IPropertyInstanceSnapshotOut, IPropertyInstance> {}
