import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";
import { useOnMount } from "@hooks/useOnMount";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { CreateDocumentButton } from "@components/CreateDocumentButton";
import { FeatureLayout } from "@components/FeatureLayout";
import { Loading } from "@components/Loading";
import RequirementsSidebar from "@components/Requirements/RequirementsSidebar/RequirementsSidebar";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

import "./RequirementsLayout.scss";

function RequirementsLayout() {
  const navigate = useNavigate();
  const workspace = useWorkspace();
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);

  useOnMount(() => {
    workspace.requirementsModule.loadSettings().finally(() => setIsLoadingSettings(false));
  });

  const handleCreateNewReqPage = async () => {
    const newDoc = await workspace.requirementsModule.createRequirementsPage();
    if (newDoc) {
      appStore.env.setActiveRequirementsDocument(newDoc.id);
      navigate(newDoc.id);
    }
  };

  const renderCreateButton = (text = "New Document") => (
    <CreateDocumentButton text={text} onClick={handleCreateNewReqPage} e2eIdentifiers="create-req-page" />
  );

  const renderCollapsedMenuContent = (text?: string) => (
    <div className="requirements-layout--collapsed-content">
      {renderCreateButton(text)}
      <RequirementsSidebar minimal />
    </div>
  );

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={<RequirementsSidebar />}
      minSize={minSize}
      maxSize={maxSize}
      topElement={renderCreateButton(menuHovered ? undefined : "")}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        open={open}
        toggle={toggle}
        title="Requirements"
        topElement={renderCreateButton()}
        collapsedContent={renderCollapsedMenuContent("")}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        <RequirementsSidebar />
      </MenuPane>

      {isLoadingSettings ? <Loading /> : <Outlet />}
    </FeatureLayout>
  );
}

export default observer(RequirementsLayout);
