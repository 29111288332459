import { Checkbox } from "@blueprintjs/core";
import { observer } from "mobx-react";

import type { IBlock } from "@store/BlockStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { createDataTestId, ElementType } from "@utilities/E2EUtils";

import styles from "./StatusTypeCheckEditor.module.scss";

type StatusTypeCheckEditorProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  block?: IBlock | null;
  disabled?: boolean;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): void;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

function StatusTypeCheckEditor(props: StatusTypeCheckEditorProps) {
  const { statusDefinition, statusInstance, disabled, onAddStatusInstance, onDeleteStatusInstance } = props;

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      onAddStatusInstance(statusDefinition, value);
    } else {
      if (!value) {
        onDeleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <div className="component--status-type-check-editor px-1.5">
      <Checkbox
        disabled={disabled}
        className={styles.statusTypeCheckEditorCheckbox}
        checked={statusInstance?.checkedValue || false}
        onChange={event => setValue(event.currentTarget.checked ? "true" : "false")}
        {...createDataTestId(ElementType.Checkbox, statusDefinition.label)}
      />
    </div>
  );
}

export type { StatusTypeCheckEditorProps };
export default observer(StatusTypeCheckEditor);
