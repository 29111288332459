import Pane from "@router/components/Panes/Pane";
import AnalysisFrame from "src/Modules/Analysis/AnalysisFrame/AnalysisFrame";

/** Main function. */
function CodeBlockIndex() {
  return (
    <Pane className="code-block-index">
      <AnalysisFrame />
    </Pane>
  );
}

/** Exports. */
export default CodeBlockIndex;
